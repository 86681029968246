<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="12" class="pt-0">
        <v-card class="elevation-0">
          <v-card-title class="pa-4">
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <search-field @search="search" :title="'Search'"></search-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="3"
                lg="3"
                xl="3"
                offset-md="5"
                offset-lg="5"
                offset-xl="5"
                order="first"
                order-sm="first"
                order-md="last"
                order-lg="last"
                order-xl="last"
              >
                <div class="float-right">
                  <fab-button
                    v-if="$moduleAccess('add')"
                    @click="openAddDialog()"
                    dark
                    top
                    xsmall
                    :icon="'mdi-plus'"
                    :tooltipSpan="'Add'"
                  ></fab-button
                  >&nbsp;
                  <fab-button
                    @click="getData()"
                    dark
                    top
                    xsmall
                    :icon="'mdi-refresh'"
                    :tooltipSpan="'Refresh'"
                  ></fab-button>
                </div>
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
        <v-divider></v-divider>
        <v-card class="elevation-0">
          <v-container fluid>
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-row>
                  <v-col cols="12" class="py-2"></v-col>
                </v-row>
              </v-col>

              <v-col
                cols="12"
                sm="12"
                md="6"
                offset-md="2"
                lg="6"
                offset-lg="2"
                xl="6"
                offset-xl="2"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="9"
                    offset-md="3"
                    lg="9"
                    offset-lg="3"
                    xl="9"
                    offset-xl="3"
                    class="py-2"
                  >
                    <div
                      class="float-right d-none d-sm-flex d-sm-none d-md-flex"
                    >
                      <p
                        class="text-xs-right grey--text caption my-2 mx-2"
                        style="font-size: 14px; margin-top: 14px; display: inline-block;"
                      >
                        Row per Page:
                      </p>
                      <v-select
                        style="font-size: 14px; max-width: 60px; display: inline-block; height: 34px;"
                        :items="page"
                        class="grey--text mx-2"
                        v-model="pageLimit"
                        @input="limitClick"
                        dense
                        height="20px"
                        :disabled="this.items.length < 10 ? true : false"
                      ></v-select>
                      <p
                        class="text-xs-center grey--text caption my-2 mx-1"
                        style="font-size: 14px;display: inline-block;"
                      >
                        {{ itemFrom + " -" + itemTo + " of " }} {{ totalItems }}
                      </p>

                      <v-btn
                        style="display: inline-block; height: 34px;"
                        fab
                        light
                        small
                        text
                        @click="backClick"
                        :disabled="disabledBack"
                      >
                        <v-icon dark>mdi-chevron-left</v-icon>
                      </v-btn>
                      <v-btn
                        style="display: inline-block; height: 34px;"
                        fab
                        light
                        small
                        text
                        @click="nextClick"
                        :disabled="disabledNext"
                      >
                        <v-icon dark>mdi-chevron-right</v-icon>
                      </v-btn>
                    </div>
                    <v-row
                      no-gutters
                      class="d-flex d-sm-none d-none d-sm-flex d-md-none"
                    >
                      <v-col cols="12" sm="12">
                        <p
                          class="text-xs-right grey--text caption my-2 mx-2"
                          style="font-size: 14px; margin-top: 14px; display: inline-block;"
                        >
                          Row per Page:
                        </p>
                        <v-select
                          style="font-size: 14px; max-width: 60px; display: inline-block; height: 34px;"
                          :items="page"
                          class="grey--text mx-2"
                          v-model="pageLimit"
                          @input="limitClick"
                          dense
                          height="20px"
                          :disabled="this.items.length < 10 ? true : false"
                        ></v-select>
                      </v-col>
                      <v-col cols="2" sm="2">
                        <div class="float-left float-sm-left">
                          <v-btn
                            style="display: inline-block; height: 34px;"
                            fab
                            light
                            small
                            text
                            @click="backClick"
                            :disabled="disabledBack"
                          >
                            <v-icon dark>mdi-chevron-left</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                      <v-col cols="8" sm="8" style="text-align: center;">
                        <p
                          class="text-xs-center grey--text caption my-2 mx-1"
                          style="font-size: 14px; display: inline-block;"
                        >
                          {{ itemFrom + " -" + itemTo + " of " }}
                          {{ totalItems }}
                        </p>
                      </v-col>
                      <v-col cols="2" sm="2">
                        <div class="float-right float-sm-right">
                          <v-btn
                            style="display: inline-block; height: 34px;"
                            fab
                            light
                            small
                            text
                            @click="nextClick"
                            :disabled="disabledNext"
                          >
                            <v-icon dark>mdi-chevron-right</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <v-divider></v-divider>
        <v-data-table
          :headers="headers"
          :items="items"
          class="elevation-1"
          disable-pagination
          hide-default-footer
          :server-items-length="items.length"
        >
          <template v-if="items.length === 0" v-slot:no-data>
            <v-container>
              <v-row>
                <v-col md="6" offset-md="3">
                  <span class="title font-weight-bold">NO DATA AVAILABLE.</span>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-else v-slot:body="{ items }">
            <tbody>
              <tr v-for="(item, index) in items" :key="index">
                <td class="text-center">
                  {{ index + queryParams.offset + 1 }}
                </td>
                <td class="text-uppercase">{{ item.name }}</td>
                <td class="text-center text-no-wrap">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="blue"
                        icon
                        @click="openViewDialog(item)"
                        v-on="on"
                      >
                        <v-icon>mdi-magnify</v-icon>
                      </v-btn>
                    </template>
                    <span>View</span>
                  </v-tooltip>
                  <v-tooltip top v-if="$moduleAccess('edit')">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="teal"
                        icon
                        @click="openEditDialog(item)"
                        v-on="on"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                  <v-tooltip top v-if="$moduleAccess('delete') && !item.super">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="red"
                        icon
                        @click="openDeleteDialog(item)"
                        v-on="on"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!---- Here is the Add, View & Edit Company Role Dialog ----->

    <full-width-dialog
      :dialog="fullWidthDialog"
      :title="dialogType + ' ' + title"
      :color="dialogColor"
      :dialogLoading="fullWidthDialogLoading"
      :dialogLoadingColor="
        dialogType === 'Add'
          ? 'amber'
          : dialogType === 'View'
          ? 'blue'
          : dialogType === 'Edit'
          ? 'teal'
          : ''
      "
      :saveButton="saveButton"
      dark
      @saveDialog="
        dialogType === 'Add'
          ? openSaveAddDialog()
          : dialogType === 'Edit'
          ? openSaveEditDialog()
          : ''
      "
      @closeDialog="
        dialogType === 'Add'
          ? closeAddDialog()
          : dialogType === 'View'
          ? closeViewDialog()
          : dialogType === 'Edit'
          ? closeEditDialog()
          : ''
      "
    >
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="12" md="1" lg="1" xl="1"></v-col>
          <v-col cols="12" sm="12" md="4" lg="4" xl="4">
            <v-card>
              <v-card-title style="background-color: #EEEEEE;"
                >Sub Company Role Details</v-card-title
              >
              <v-divider></v-divider>
              <v-card-text>
                <v-form ref="validateName">
                  <v-text-field
                    v-model="data.name"
                    label="Name"
                    required
                    :rules="[rules.required]"
                    :readonly="dialogType === 'View'"
                  ></v-text-field>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <v-card>
              <v-card-title style="background-color: #EEEEEE;"
                >Access Level</v-card-title
              >
              <v-divider></v-divider>
              <v-card-text class="scrolling-wrapper">
                <v-data-table
                  v-model="selectedMenu"
                  :headers="headersSelectPages"
                  :items="itemsSelectPages"
                  class="elevation-1"
                  disable-pagination
                  hide-default-footer
                  :show-select="dialogType !== 'View'"
                  :server-items-length="itemsSelectPages.length"
                >
                  <template v-if="itemsSelectPages.length === 0" v-slot:no-data>
                    <v-container>
                      <v-row>
                        <v-col md="6" offset-md="3">
                          <span class="title font-weight-bold"
                            >NO DATA AVAILABLE.</span
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </template>
                  <template v-else v-slot:body="{ items }">
                    <tbody>
                      <tr v-for="(item, index) in items" :key="index">
                        <td
                          v-if="dialogType !== 'View'"
                          class="text-no-wrap text-center"
                        >
                          <v-checkbox
                            v-model="selectedMenu"
                            class="mt-0 pt-0"
                            color="primary"
                            hide-details
                            :value="item"
                          ></v-checkbox>
                        </td>
                        <td class="text-no-wrap text-uppercase">
                          {{ item.name }}
                        </td>
                        <td class="text-no-wrap">{{ item.link }}</td>
                        <td class="text-no-wrap text-center">
                          <v-tooltip top v-if="dialogType === 'View'">
                            <template v-slot:activator="{ on }">
                              <v-btn
                                icon
                                @click="openModulesDialogView(item)"
                                v-on="on"
                              >
                                <v-icon>mdi-folder-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Module</span>
                          </v-tooltip>
                          <v-tooltip top v-if="dialogType !== 'View'">
                            <template v-slot:activator="{ on }">
                              <v-btn
                                icon
                                @click="openModulesDialog(item)"
                                v-on="on"
                                :disabled="
                                  !selectedMenu.find(
                                    (data) => data.id === item.id
                                  )
                                "
                              >
                                <v-icon>mdi-folder-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Module</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="1" lg="1" xl="1"></v-col>
        </v-row>
      </v-container>
    </full-width-dialog>

    <!---- Here is the Module Dialog ---->

    <mini-dialog
      :dialog="modulesDialog"
      :max-width="'600px'"
      :loading="confirmLoading"
      :cardActions="dialogType === 'View' ? false : true"
      @closeDialog="closeModulesDialog"
      @confirmDialog="
        dialogType === 'Add'
          ? addModules()
          : dialogType === 'Edit'
          ? editModules()
          : ''
      "
    >
      <v-card-title class="pl-3">
        <span class="headline">Select Module</span>
        <v-spacer></v-spacer>
        <v-btn v-if="dialogType === 'View'" icon @click="closeModulesDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container v-if="modulesDialogLoading" style="text-align: center;">
          <v-row>
            <v-col cols="12">
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-container>
        <v-data-table
          v-else
          v-model="moduleItems"
          :headers="headersModuleItems"
          :items="itemsModuleItems"
          class="elevation-1"
          disable-pagination
          :show-select="dialogType !== 'View'"
          hide-default-footer
          :server-items-length="itemsModuleItems.length"
        >
          <template v-if="itemsModuleItems.length === 0" v-slot:no-data>
            <v-container>
              <v-row>
                <v-col md="6" offset-md="3">
                  <span class="title font-weight-bold">NO DATA AVAILABLE.</span>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-else v-slot:body="{ items }">
            <tbody>
              <tr v-for="(item, index) in items" :key="index">
                <td
                  v-if="dialogType !== 'View'"
                  class="text-no-wrap text-center"
                >
                  <v-checkbox
                    v-model="moduleItems"
                    class="mt-0 pt-0"
                    color="primary"
                    hide-details
                    :value="item"
                  ></v-checkbox>
                </td>
                <td class="text-no-wrap text-uppercase">{{ item.name }}</td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card-text>
    </mini-dialog>

    <!---- Here is the Confirmation of Saving Company Role ---->

    <mini-dialog
      :dialog="confirmationDialog"
      :max-width="'400'"
      :confirmSave="true"
      :loading="confirmLoading"
      @closeDialog="
        dialogType === 'Add'
          ? closeSaveAddDialog()
          : dialogType === 'Edit'
          ? closeSaveEditDialog()
          : ''
      "
      @confirmDialog="
        dialogType === 'Add'
          ? addSubCompanyRole()
          : dialogType === 'Edit'
          ? editSubCompanyRole()
          : ''
      "
    ></mini-dialog>

    <!---- Here is the Confirmation of Deleting Company Role ---->

    <mini-dialog
      :dialog="deleteDialog"
      :max-width="'400'"
      :confirmSave="true"
      :cardTitle="'Delete'"
      :cardText="'Are you sure you want to delete this?'"
      :loading="confirmLoading"
      @closeDialog="closeDeleteDialog"
      @confirmDialog="deleteSubCompanyRole()"
    ></mini-dialog>
  </v-container>
</template>

<script>
import Services from "@/services/Services";

export default {
  data: () => {
    return {
      title: "Sub Company Role",
      headers: [
        {
          text: "#",
          sortable: false,
          align: "center",
        },
        { text: "Name", sortable: false },
        { text: "Action", sortable: false, align: "center" },
      ],
      items: [],
      data: {},
      queryParams: {
        search: "",
        limit: 10,
        offset: 0,
      },
      totalItems: 0,
      itemFrom: 0,
      itemTo: 0,
      disabledNext: false,
      disabledBack: true,
      disabledLimit: true,
      page: [
        { text: 10, value: 10 },
        { text: 20, value: 20 },
        { text: 30, value: 30 },
        { text: 40, value: 40 },
        { text: 50, value: 50 },
        { text: 60, value: 60 },
        { text: 70, value: 70 },
        { text: 80, value: 80 },
        { text: 90, value: 90 },
        { text: 100, value: 100 },
      ],
      confirmLoading: false,
      fullWidthDialog: false,
      deleteDialog: false,
      confirmationDialog: false,
      rules: {
        required: (value) => !!value || "This field is required.",
      },
      headersSelectPages: [
        {
          text: "Name",
          sortable: false,
        },
        {
          text: "Link",
          sortable: false,
        },
        { text: "Module", sortable: false, align: "center" },
      ],
      itemsSelectPages: [],
      selectedMenu: [],
      headersModuleItems: [
        {
          text: "Name",
          sortable: false,
        },
      ],
      itemsModuleItems: [],
      moduleItems: [],
      modulesDialog: false,
      setId: null,
      dialogType: "",
      dialogColor: "",
      saveButton: false,
      fullWidthDialogLoading: false,
      modulesDialogLoading: false,
    };
  },
  created: function() {
    if (this.$store.state.token !== null) {
      this.getData();
    } else {
      this.$router.push({
        path: "/",
      });

      this.$store.dispatch("clearStore");
    }
  },
  computed: {
    pageLimit: {
      get() {
        return 10;
      },
      set(val) {
        this.queryParams.limit = val;
        this.queryParams.offset = 0;
      },
    },
  },
  methods: {
    async search(data) {
      this.queryParams.search = data;
      await this.getData();
    },
    async getData() {
      this.$store.dispatch("startLoading");

      try {
        let response = (await Services.getSubCompanyRoles(this.queryParams))
          .data;

        this.items = response.data;

        // Assign Total number of data
        this.totalItems = response.count;

        // Assign Number started the data
        this.itemFrom = this.totalItems > 0 ? this.queryParams.offset + 1 : 0;

        // Assign Number end the data
        this.itemTo = this.queryParams.offset + this.queryParams.limit;

        if (this.totalItems <= this.itemTo) {
          this.itemTo = this.totalItems;
        }

        // Conditions for NextPage Button
        if (this.itemTo === this.totalItems) {
          this.disabledNext = true;
        } else {
          this.disabledNext = false;
        }
        // Conditions for BackPage Button
        if (this.itemFrom > 1) {
          this.disabledBack = false;
        } else {
          this.disabledBack = true;
        }

        if (this.totalItems <= this.queryParams.limit) {
          this.disabledLimit = true;
        } else {
          this.disabledLimit = false;
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network or server connection",
          });
        }
      }

      this.$store.dispatch("endLoading");
    },
    nextClick() {
      this.queryParams.offset += this.queryParams.limit;
      this.getData();
    },
    backClick() {
      this.queryParams.offset -= this.queryParams.limit;
      this.getData();
    },
    limitClick() {
      this.queryParams.offset = 0;
      this.getData();
    },
    async openViewDialog(item) {
      this.fullWidthDialog = true;
      this.fullWidthDialogLoading = true;
      this.dialogType = "View";
      this.dialogColor = "blue";
      this.saveButton = false;
      this.data.name = item.name.toUpperCase();
      const data = await this.setSubCompanyRoleById(item.id);
      this.itemsSelectPages = await data.menu;
      setTimeout(() => {
        this.fullWidthDialogLoading = false;
      }, 600);
    },
    closeViewDialog() {
      this.fullWidthDialog = false;
      this.dialogType = "";
      this.dialogColor = "";
      this.saveButton = false;
      this.data = {};
      this.itemsSelectPages.length = 0;
    },
    async openAddDialog() {
      this.fullWidthDialog = true;
      this.fullWidthDialogLoading = true;
      this.dialogType = "Add";
      this.dialogColor = "amber";
      this.saveButton = true;

      const setPages = await this.setPages();

      this.itemsSelectPages = setPages.map((data) => {
        const object = {
          id: null,
          name: null,
          link: null,
          modules: null,
        };

        object.id = data.id;
        object.name = data.name;
        object.link = data.link;

        return object;
      });

      setTimeout(() => {
        this.fullWidthDialogLoading = false;
      }, 600);
    },
    closeAddDialog() {
      this.fullWidthDialog = false;
      this.dialogType = "";
      this.dialogColor = "";
      this.saveButton = false;
      this.data = {};
      this.itemsSelectPages.length = 0;
      this.selectedMenu.length = 0;
    },
    async openModulesDialogView(data) {
      const modules = await data.modules;

      if (modules.length !== 0) {
        this.modulesDialog = true;
        this.modulesDialogLoading = true;
        this.itemsModuleItems = modules;
        setTimeout(() => {
          this.modulesDialogLoading = false;
        }, 600);
      } else {
        this.$infoBar({
          type: "error",
          text: "No Module Selected",
        });
      }
    },
    async openModulesDialog(data) {
      this.setId = data.id;
      this.itemsModuleItems = await this.setModules(data.id);
      if (this.itemsModuleItems.length !== 0) {
        this.modulesDialog = true;
        this.modulesDialogLoading = true;
        if (this.dialogType === "Edit") {
          const pageChosen = await this.setSubCompanyRoleById(this.data.id);
          const object = pageChosen.menu.find((item) => {
            return item.id === data.id;
          });
          if (object) {
            if (object.modules) {
              if (object.modules.length !== 0) {
                this.moduleItems = object.modules.map((item) => {
                  const returnedData = this.itemsModuleItems.find((datus) => {
                    return datus.name === item.name;
                  });

                  return returnedData;
                });
              }
            }
          }
        }
        setTimeout(() => {
          this.modulesDialogLoading = false;
        }, 600);
      } else {
        this.$infoBar({
          type: "error",
          text: "No Modules Available",
        });
      }
    },
    closeModulesDialog() {
      this.modulesDialog = false;
    },
    async addModules() {
      const index = await this.itemsSelectPages.findIndex(
        (item) => item.id === this.setId
      );

      const modules = this.moduleItems.filter((item) => {
        return item.pageId === this.setId;
      });

      this.itemsSelectPages[index].modules = modules;
      this.itemsModuleItems.length = 0;
      this.modulesDialog = false;
    },
    async editModules() {
      const index_1 = await this.selectedMenu.findIndex(
        (item) => item.id === this.setId
      );
      const index_2 = await this.itemsSelectPages.findIndex(
        (item) => item.id === this.setId
      );

      const modules = this.moduleItems.filter((item) => {
        return item.pageId === this.setId;
      });

      this.selectedMenu[index_1].modules = modules;
      this.itemsSelectPages[index_2].modules = modules;

      this.itemsModuleItems.length = 0;
      this.modulesDialog = false;
    },
    openSaveAddDialog() {
      if (this.selectedMenu.length !== 0) {
        if (this.$refs.validateName.validate()) {
          this.confirmationDialog = true;
        }
      } else {
        this.$infoBar({
          type: "error",
          text: "Please Select Page/s",
        });
      }
    },
    closeSaveAddDialog() {
      this.confirmationDialog = false;
    },
    async openEditDialog(item) {
      this.fullWidthDialog = true;
      this.fullWidthDialogLoading = true;
      this.dialogType = "Edit";
      this.dialogColor = "teal";
      this.saveButton = true;
      this.data.id = item.id;
      this.data.name = item.name.toUpperCase();

      const setPages = await this.setPages();

      this.itemsSelectPages = await Promise.all(
        setPages.map(async (data) => {
          const object = {
            id: null,
            name: null,
            link: null,
            modules: null,
          };

          object.id = data.id;
          object.name = data.name;
          object.link = data.link;

          const CompanyRoleData = await this.setSubCompanyRoleById(item.id);

          const findMenu = CompanyRoleData.menu.find((datus) => {
            return datus.id === data.id;
          });

          if (findMenu) {
            object.modules = findMenu.modules;
          }

          return object;
        })
      );

      const data = await this.setSubCompanyRoleById(item.id);

      this.selectedMenu = await Promise.all(
        data.menu.map((data) => {
          return {
            id: data.id,
            link: data.link,
            modules: data.modules,
            name: data.name,
          };
        })
      );

      setTimeout(() => {
        this.fullWidthDialogLoading = false;
      }, 600);
    },
    closeEditDialog() {
      this.fullWidthDialog = false;
      this.dialogType = "";
      this.dialogColor = "";
      this.saveButton = false;
      this.data = {};
      this.itemsSelectPages.length = 0;
      this.selectedMenu.length = 0;
    },
    openSaveEditDialog() {
      if (this.selectedMenu.length !== 0) {
        if (this.$refs.validateName.validate()) {
          this.confirmationDialog = true;
        }
      } else {
        this.$infoBar({
          type: "error",
          text: "Please Select Page/s",
        });
      }
    },
    closeSaveEditDialog() {
      this.confirmationDialog = false;
    },
    openDeleteDialog(item) {
      this.deleteDialog = true;
      this.data.id = item.id;
    },
    closeDeleteDialog() {
      this.deleteDialog = false;
    },
    async addSubCompanyRole() {
      this.confirmLoading = true;

      const body = {
        name: this.data.name,
        menu: this.selectedMenu,
        company_id: this.$store.state.company.id,
      };

      try {
        const response = await Services.addSubCompanyRole(body);

        if (
          response.status === 201 ||
          response.status === 204 ||
          response.status === 200
        ) {
          this.closeAllDialog();
          await this.getData();
          this.$infoBar({
            type: "success",
            text: this.title + " has been added!",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: "Server Error!",
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network connection",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message) + "!",
          });
        }
      }
      this.confirmLoading = await false;
    },
    async editSubCompanyRole() {
      this.confirmLoading = true;

      const body = {
        name: this.data.name,
        menu: this.selectedMenu,
      };

      try {
        const response = await Services.editSubCompanyRole(body, this.data.id);

        if (
          response.status === 201 ||
          response.status === 204 ||
          response.status === 200
        ) {
          this.closeAllDialog();
          await this.getData();
          this.$infoBar({
            type: "success",
            text: this.title + " has been edited!",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: "Server Error!",
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network connection",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message) + "!",
          });
        }
      }
      this.confirmLoading = await false;
    },
    async deleteSubCompanyRole() {
      this.confirmLoading = true;

      try {
        const response = await Services.deleteSubCompanyRole(this.data.id);

        if (
          response.status === 201 ||
          response.status === 204 ||
          response.status === 200
        ) {
          this.closeAllDialog();
          await this.getData();
          this.$infoBar({
            type: "success",
            text: this.title + " has been deleted!",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: "Server Error!",
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network connection",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message) + "!",
          });
        }
      }
      this.confirmLoading = await false;
    },
    async setSubCompanyRoleById(id) {
      let data;

      try {
        const response = await Services.getSubCompanyRole(id);
        data = response.data;
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network or server connection",
          });
        }
      }

      return data;
    },
    async setPages() {
      let data;

      try {
        const response = await Services.getSubCompanyPages({});
        data = response.data.data;
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network or server connection",
          });
        }
      }

      return data;
    },
    async setModules(id) {
      let data;

      try {
        const response = await Services.getSubCompanyModules({ page_id: id });
        data = response.data.data;
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network or server connection",
          });
        }
      }

      return data;
    },
    closeAllDialog() {
      this.closeAddDialog();
      this.closeEditDialog();
      this.closeSaveAddDialog();
      this.closeSaveEditDialog();
      this.closeDeleteDialog();
    },
  },
};
</script>

<style></style>
